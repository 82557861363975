//imports + requires
import LocomotiveScroll from "locomotive-scroll";
import { gsap } from "gsap";

import TweenMax from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import lottie from "lottie-web";
require("jquery.marquee");
import Swup from "swup";
import SwupGaPlugin from '@swup/ga-plugin';

var Accordion = require("./utils/accordion.js");
var Flickity = require("flickity");
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import LazyLoad from "vanilla-lazyload";

//variables
var styleDir = object_name.stylesheetDirectory;
const $ = require("jquery");
const body = document.querySelector("body");
const html = document.querySelector("html");
const homeLink = document.querySelector(".logo-link");

//swup setup
const swup = new Swup({
  animationSelector: '[class*="swup-transition-"]',
  plugins: [new SwupBodyClassPlugin(),new SwupGaPlugin({

    gaMeasurementId: "G-YGJ886364H",

  })],
});

// document.querySelector(".wt-cli-cookie-bar-container").setAttribute("data-scroll", "");
// document.querySelector(".wt-cli-cookie-bar-container").setAttribute("data-scroll-sticky", "");
// document.querySelector(".wt-cli-cookie-bar-container").setAttribute("data-scroll-target", "#swup");

const scrollContainer = document.querySelector(".scroll-container");
const scroller = new LocomotiveScroll({
  el: scrollContainer,
  smooth: true,
  multiplier: 1.5,
  mobile: {
    smooth: false,
  },
  tablet: {
    smooth: false,
  },
});



//lottie animations
let introAnimation;
let introAnimationMobile;
let logoAnimation;
var introAnimate = document.querySelector(".intro-animate");
var introAnimateMobile = document.querySelector(".intro-animate-mobile");
var logoAnimate = document.querySelector(".logo-animate");
const button = document.getElementById("menu-toggle");
const menuWrapper = document.querySelector(".main-menu-wrapper");
const logoBG = document.querySelector("#logo-bg");
const loadingBG = document.querySelector("#loading-bg");

introAnimation = lottie.loadAnimation({
  container: introAnimate, // the dom element that will contain the animation
  renderer: "svg",
  loop: false,
  autoplay: true,
  path: styleDir + "/dist/img/intro-message-9.json", // the path to the animation json
});

introAnimationMobile = lottie.loadAnimation({
  container: introAnimateMobile, // the dom element that will contain the animation
  renderer: "svg",
  loop: false,
  autoplay: true,
  path: styleDir + "/dist/img/intro-message-mobile-1.json", // the path to the animation json
});

logoAnimation = lottie.loadAnimation({
  container: logoAnimate, // the dom element that will contain the animation
  renderer: "svg",
  loop: false,
  autoplay: false,
  path: styleDir + "/dist/img/logo-3.json", // the path to the animation json
});

//main navigation open and play animations
button.addEventListener("click", function() {
  if (body.classList.contains("menu-open")) {
    logoAnimation.playSegments([1, 16], true);
    button.setAttribute("aria-expanded", "false");
    body.classList.remove("menu-open");
    var logoState = "closed";
    logoBG.style.transform = "scale(1, 1)";
  } else {
    logoAnimation.playSegments([16, 1], true);
    button.setAttribute("aria-expanded", "true");
    body.classList.add("menu-open");
    var logoState = "open";
    logoBG.style.transform = "scale(" + window.innerWidth / logoBG.offsetWidth + ", " + window.innerHeight / logoBG.offsetHeight + ")";
  }
});

window.addEventListener(
  "resize",
  function() {
    if (body.classList.contains("menu-open")) {
      logoBG.style.transform = "scale(" + window.innerWidth / logoBG.offsetWidth + ", " + window.innerHeight / logoBG.offsetHeight + ")";
    }
  },
  true
);
window.addEventListener(
  "resize",
  function() {
    if (body.classList.contains("loading-open")) {
      loadingBG.style.transform = "scale(" + window.innerWidth / logoBG.offsetWidth + ", " + window.innerHeight / logoBG.offsetHeight + ")";
    }
  },
  true
);

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}


var scrollbar = document.querySelector('.c-scrollbar');
var cookiebar = document.querySelector('.wt-cli-cookie-bar-container');
if (cookiebar && scrollbar) {
  scrollbar.after(cookiebar);
}

/////////////////////////////////////////
function ImageNowScripts() {
  var balloonContainer = document.querySelector(".balloon-animation");
  var megaphoneContainer = document.querySelector(".megaphone-animation");
  var starContainer = document.querySelector(".star-animation");
  var doorContainer = document.querySelector(".door-animation");
  var pyramidContainer = document.querySelector(".pyramid-animation");

  var balloonAnimation = lottie.loadAnimation({
    container: balloonContainer, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: styleDir + "/dist/img/about-animations/balloon.json", // the path to the animation json
  });
  var megaphoneAnimation = lottie.loadAnimation({
    container: megaphoneContainer, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: styleDir + "/dist/img/about-animations/megaphone.json", // the path to the animation json
  });
  var pyramidAnimation = lottie.loadAnimation({
    container: pyramidContainer, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: styleDir + "/dist/img/about-animations/pyramid.json", // the path to the animation json
  });
  var doorAnimation = lottie.loadAnimation({
    container: doorContainer, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: styleDir + "/dist/img/about-animations/door-2.json", // the path to the animation json
  });
  var starAnimation = lottie.loadAnimation({
    container: starContainer, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: styleDir + "/dist/img/about-animations/star-2.json", // the path to the animation json
  });

  const copyMailId = document.querySelectorAll(".copy-email");
  copyMailId.forEach((copyText) => {
    copyText.addEventListener("click", (e) => {
      if (html.classList.contains("no-dev")) {
        e.preventDefault;
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(copyText);
        selection.removeAllRanges();
        selection.addRange(range);
        try {
          document.execCommand("copy");
          selection.removeAllRanges();

          const mailId = copyText.textContent;
          copyText.textContent = "Copied to clipboard";
          copyText.classList.add("success");

          setTimeout(() => {
            copyText.textContent = mailId;
            copyText.classList.remove("success");
          }, 1000);
        } catch (e) {
          copyText.classList.add("error");

          setTimeout(() => {
            errorMsg.classList.remove("show");
          }, 1200);
        }
      }
    });
  });

  body.classList.remove("scrolled");
  var scrolledState = false;
  const menuLinks = document.querySelectorAll("ul#in-nav > .page_item > a");
  if (body.classList.contains("home") && !html.classList.contains("start")) {
    logoState = "open";
    loadingBG.style.transform = "scale(" + window.innerWidth / logoBG.offsetWidth + ", " + window.innerHeight / logoBG.offsetHeight + ")";
    setTimeout(function() {
      body.classList.remove("loading-open");
      if (!scrolledState && body.classList.contains("home")) {
        logoAnimation.playSegments([1, 16], true);
        logoState = "closed";
        loadingBG.style.transform = "scale(1, 1)";
        scrolledState = true;
      }
    }, 13999);

    menuLinks.forEach((i) => {
      i.addEventListener("click", (e) => {
        button.setAttribute("aria-expanded", "false");
        logoAnimation.playSegments([1, 16], true);
        logoState = "closed";
        body.classList.remove("menu-open");
        logoBG.style.transform = "scale(1, 1)";
      });
    });
  } else {
    body.classList.remove("loading-open");
    if (!html.classList.contains("start")) {
      logoAnimation.goToAndStop(16, true);
      var logoState = "closed";
    }

    // console.log("any other situation")

    homeLink.addEventListener("click", function() {
      if (body.classList.contains("menu-open")) {
        logoAnimation.playSegments([1, 16], true);
        button.setAttribute("aria-expanded", "false");
        body.classList.remove("menu-open");
        logoBG.style.transform = "scale(1, 1)";

        logoState = "closed";
      } else {
        // logoAnimation.goToAndStop(16, true);
        logoState = "open";
      }
    });

    menuLinks.forEach((i) => {
      i.addEventListener("click", (e) => {
        button.setAttribute("aria-expanded", "false");
        logoAnimation.playSegments([1, 16], true);
        logoBG.style.transform = "scale(1, 1)";
        logoState = "closed";
        body.classList.remove("menu-open");
      });
    });
  }

  if (body.classList.contains("home")) {
    homeLink.setAttribute("data-no-swup", "");
  } else {
    if (homeLink.hasAttribute("data-no-swup")) {
      homeLink.removeAttribute("data-no-swup");
    }
  }

  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;
  let cHeight = window.innerHeight;
  let cBar = document.querySelector('#cookie-law-info-bar');



  scroller.on("scroll", (position) => {
    

    if (cBar) {
      scroller.on("scroll", (position) => {
        if (position.scroll.y > cHeight) {
          cBar.classList.add('reveal');
        } else {
          cBar.classList.remove('reveal');
        }
      });
    };


    if (position.scroll.y > 1) {
      body.classList.add("scrolled");
      if (body.classList.contains("home") && body.classList.contains("loading-open")) {
        body.classList.remove("loading-open");
        logoAnimation.playSegments([1, 16], true);
        logoState = "closed";
        logoBG.style.transform = "scale(1, 1)";
        loadingBG.style.transform = "scale(1, 1)";
      }
    }

    if (position.scroll.y > 1000) {
      const currentScroll = position.scroll.y;
      html.classList.add("hide-loading");
      if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    }
  });

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 500) {
      html.classList.add("hide-loading");
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    }
  });

  swup.on("contentReplaced", function() {
    setTimeout(function() {
      body.classList.add("ready");
    }, 200);
  });

  //accordions
  var accessibleAcc = function() {
    var options = {
      item: ".expandable-section",
      target: ".target",
      control: ".target", // in this case the target is also acting as the control
      panel: ".panel",
      allowMultiple: false,
      attribute: "data-status",
      expanded: "tab-open",
      contracted: "tab-closed",
      prefix: "accordion-",
      transition: "height .5s",
      setFocus: "none", // options: none, item, panel, target, control, first
      hashEnabled: true, // use hash in URL to open accordion item
    };
    var inAccordions = document.querySelectorAll(".in-accordion").forEach((i) => {
      var inAccordion = new Accordion(i, options);
    });
  };

  accessibleAcc();

  function pageTopMargin() {
    var header = document.querySelector("header");
    var headerH = header.offsetHeight;
    var page = document.querySelector("#primary");
    page.style.marginTop = headerH - 1 + "px";
  }

  $(".expandable-tab").on("click", function() {
    if (
      $(this)
        .parent()
        .parent()
        .hasClass("accordion-open")
    ) {
      $(this)
        .parent()
        .parent()
        .removeClass("accordion-open");
    } else {
      $(this)
        .parent()
        .parent()
        .addClass("accordion-open");
      $(this)
        .parent()
        .parent()
        .siblings()
        .removeClass("accordion-open");
    }
  });

  const accordionElem = document.querySelector(".in-accordion");
  if (accordionElem) {
    accordionElem.addEventListener(
      "click",
      function() {
        setTimeout(function() {

          window.dispatchEvent(new Event("resize"));
        }, 250);
      },
      false
    );
  }

  //lazy load options

  const lazyLoadOptions = {
    elements_selector: ".lazy",
    callback_enter: scrollRecalculate,
    unobserve_entered: true, // Stop observing .horizContainer(s) after they entered
  };

  const pageLazyLoad = new LazyLoad(lazyLoadOptions);
  pageLazyLoad.update();

  function scrollRecalculate(element) {
    if (element.classList.contains("lazy-video")) {
      setTimeout(function() {
        console.log("recaluclate for video");
        scroller.update();
      }, 250);
    }
  }

  //Carousels
  var workOptions = {
    cellAlign: "left",
    contain: true,
    wrapAround: true,
    resize: true,
    draggable: true,
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: true,
    imagesLoaded: true,
  };

  var workOptionsRtoL = {
    cellAlign: "left",
    contain: true,
    wrapAround: true,
    resize: true,
    draggable: true,
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: true,
    imagesLoaded: true,
  };

  if (matchMedia("screen and (max-width: 767px)").matches) {
    workOptions.draggable = true;
    workOptions.autoPlay = 5000;
    workOptions.wrapAround = false;
  }

  if (matchMedia("screen and (max-width: 767px)").matches) {
    workOptionsRtoL.draggable = true;
    workOptionsRtoL.autoPlay = 5000;
    workOptionsRtoL.wrapAround = false;
    workOptions.rightToLeft = true;
  }

  var workCarousel = document.querySelectorAll(".work-carousel");
  var wCarousel = function() {
    workCarousel.forEach((i) => {
      let flkty = new Flickity(i, workOptions);
    });
  };
  var workCarouselRtoL = document.querySelectorAll(".work-carousel-r-l");
  var wCarouselRtoL = function() {
    workCarouselRtoL.forEach((i) => {
      let flkty = new Flickity(i, workOptionsRtoL);
    });
  };

  if (workCarouselRtoL) {
    wCarouselRtoL();
  }

  if (workCarousel) {
    wCarousel();
  }
  var horizontalCarousel = document.querySelectorAll(".horizontal-carousel");
  var hCarousel = function() {
    horizontalCarousel.forEach((i) => {
      let flkty = new Flickity(i, {
        cellAlign: "left",
        contain: true,
        wrapAround: false,
        resize: true,
        draggable: true,
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,
      });
      setTimeout(function() {
        flkty.resize();
      }, 1000);
    });
  };
  if (horizontalCarousel) {
    hCarousel();
  }

  var carousel = document.querySelectorAll(".news-carousel");
  var newsCarousel = function() {
    carousel.forEach((i) => {
      var cards = document.querySelectorAll(".carousel-card").length;
      var next = document.querySelector(".next");
      var prev = document.querySelector(".prev");
      let flkty = new Flickity(i, {
        cellAlign: "left",
        contain: false,
        wrapAround: false,
        resize: true,
        draggable: true,
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,

        on: {
          change: function(index) {
            if (index == 0) {
              prev.classList.add("disabled");
            } else {
              prev.classList.remove("disabled");
            }
            if (index == cards - 1) {
              next.classList.add("disabled");
            } else {
              next.classList.remove("disabled");
            }
          },
        },
      });

      next.addEventListener("click", function() {
        flkty.next();
      });
      prev.addEventListener("click", function() {
        flkty.previous();
      });

      setTimeout(function() {
        flkty.resize();
   
        window.dispatchEvent(new Event("resize"));
        $(window).trigger("resize");
      }, 1200);
    });
  };

  if (carousel) {
    newsCarousel();
  }

  var cardOptions = {
    cellAlign: "left",
    contain: true,
    wrapAround: false,
    resize: true,
    draggable: true,
    prevNextButtons: false,
    pageDots: false,
    initialIndex: ".is-initial",
  };

  if (matchMedia("screen and (max-width: 767px)").matches) {
    cardOptions.draggable = false;
  }

  var cCarousel = document.querySelectorAll(".card-carousel");
  var cardCarousel = function() {
    var cards = document.querySelectorAll(".card-slide").length;
    var next = document.querySelector(".cc-next");
    var prev = document.querySelector(".cc-prev");
    cCarousel.forEach((i) => {
      let flkty = new Flickity(i, {
        cellAlign: "left",
        contain: true,
        wrapAround: false,
        resize: true,
        draggable: false,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: ".is-initial",
        on: {
          change: function(index) {
            if (index == 0) {
              prev.classList.add("disabled");
            } else {
              prev.classList.remove("disabled");
            }
            if (index == cards - 1) {
              next.classList.add("disabled");
            } else {
              next.classList.remove("disabled");
            }
          },
        },
      });
      document.querySelector(".cc-next").addEventListener("click", function() {
        flkty.next();
      });
      document.querySelector(".cc-prev").addEventListener("click", function() {
        flkty.previous();
      });

      setTimeout(function() {
        flkty.resize();
      }, 1000);
    });
  };

  if (cCarousel) {
    cardCarousel();
  }
  $(".card-slide").on("click", function() {
    $(this)
      .siblings()
      .removeClass("is-selected");
    $(this).addClass("is-selected");
  });

  //marquee
  $(".headline-marquee").marquee({
    duration: 15000,
    delayBeforeStart: -63000,
    duplicated: false,
    direction: "left",
  });

  setTimeout(function() {
    html.classList.add("start");
  }, 100);

  window.onload = function() {
    setTimeout(function() {
      html.classList.add("loading-complete");
    }, 100);

    setTimeout(function() {
      body.classList.add("ready");
    }, 200);
    setTimeout(function() {
      document.querySelector("#loading").remove();
    }, 300);
  };

  //scroller stuff
  scroller.on("scroll", ScrollTrigger.update);

  //   var hzPanel = document.querySelectorAll(".horizontal-panel");
  //   var hzPanels = function() {
  //     hzPanel.forEach((i) => {
  //          i.style.width = (window.innerWidth * .75) +"px";

  //       });
  //   };

  //   if (hzPanel) {
  //   hzPanels();
  // }

  ScrollTrigger.scrollerProxy(scrollContainer, {
    scrollTop(value) {
      return arguments.length ? scroller.scrollTo(value, 0, 0) : scroller.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        left: 0,
        top: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    pinType: scrollContainer.style.transform ? "transform" : "fixed",
  });

  //Locomotive scroll horizonal section
  var pinWrap = document.querySelector(".pin-wrap");
  if (pinWrap) {
    let pinWrapWidth = pinWrap.offsetWidth;
    let horizontalScrollLength = pinWrapWidth - window.innerWidth;

    var scrollTriggerOptions = {
      scrollTrigger: {
        scroller: scrollContainer, //locomotive-scroll
        scrub: true,
        trigger: ".horizontal-scroll",
        pin: true,
        // anticipatePin: 1,
        start: "top top",
        end: pinWrapWidth,
      },
      x: -horizontalScrollLength,
      ease: "none",
    };

    gsap.to(".pin-wrap", scrollTriggerOptions);
  }

  // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
  ScrollTrigger.addEventListener("refresh", () => scroller.update());

  // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
  ScrollTrigger.refresh();

  scroller.scrollTo("top", {
    offset: 0,
    duration: 0,
    disableLerp: true,
  });

  window.scrollTo(0, 0);

  var videoElement = document.querySelector("#head-video");


;
  if  (videoElement) {
  $('body').on('click touchstart', function () {
  
    if (videoElement.playing) {
  
    }
    else {
  
        videoElement.play();
    }
  });
  }
}

ImageNowScripts();
swup.on("contentReplaced", ImageNowScripts);
